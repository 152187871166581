html,
body,
.main-body {
  height: auto;
  width: auto;
  min-width: 0;
  min-height: 0; }

body,
.main-body {
  background: none; }

.main-header,
.main-footer,
.aside {
  display: none; }

.main-body {
  margin: 0;
  width: auto;
  padding: 0; }

.main-ariane,
.ariane,
.richContent {
  float: none !important; }
